import React from 'react';
// import Header from '../lib-ui/Header';
import { useRef, useEffect, useState } from 'react';
import TurtleAppBar from '../lib/AppBar';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';


let map = null;
var markers = [];

export default () => {

    const [vehicles, setVehicles] = useState([]);

    const [selectedVehicle, setSelectedVehicle] = useState(-1);
    const [markerColor, setrMarkerColor] = useState([]);
  
    const mapRef = useRef(null);
    const lat = 36.347939;//37.508225;
    const lng = 127.369223;//127.060826;
    const { naver } = window;

    //const mapRef = useRef(null);

    useEffect(() => {

        // Check if naver is defined (API script loaded)
        if (!window.naver) return;

        const mapOptions = {
            center: new window.naver.maps.LatLng(lat,lng), //(37.5665, 126.9780), // Seoul coordinates
            zoom: 18,
            padding: 0,
        };

        // Initialize the map
        map = new window.naver.maps.Map(mapRef.current, mapOptions);
        const markerContent = `
  <div style="width:50px;height:20px;background-color:#555555;color:#ffffff;border-radius:3px;font-size:11px;box-shadow:2px 2px 3px #afafaf;text-align:center;display:flex;justify-content:center;align-items:center">YS10091</div>
`;


        /*
                new naver.maps.Marker({
                    // 생성될 마커의 위치
                    position: new naver.maps.LatLng(37.508622, 127.062154),
                    // 마커를 표시할 Map 객체
                    icon: {
                        content: markerContent,
                        anchor: new naver.maps.Point(0, 50),  // 마커의 위치 설정
                    },
                    map: map
                });
        */

        /*
        let v = [];
        for(let i=0;i<500;i++){
            v.push({
                id:i,
                name:'Turtle_'+i,
                imei:'IMEI-'+i,
                qr_code: 'YS_'+i
            });
        }

        setVehicles(v);
        */
        //  
        // console.log('64 data: ', v_data);

        let tc = [];

        const fetchData = async () => {
            try {
                const response = await fetch('https://app-dev.korea-turtle.co.kr/api/v2-iot/admin/map');
                const data = await response.json();
                //  console.log('72', data.data);
                let t = [];
                for (let i = 0; i < data.data.vehicle_list.length; i++) {
                    t.push({
                        id: data.data.vehicle_list[i].id,
                        idx: i,
                        name: data.data.vehicle_list[i].name,
                        qr_code: data.data.vehicle_list[i].qr_code,
                        imei: data.data.vehicle_list[i].imei,
                        battery: data.data.vehicle_list[i].battery,
                        latitude: parseFloat(data.data.vehicle_list[i].latitude),
                        longitude: parseFloat(data.data.vehicle_list[i].longitude),
                        locked: data.data.vehicle_list[i].is_locked == 1 ? 'YES' : 'NO'
                    });




                }

                let t_marker;
                setVehicles(t);
                console.log(t.length);
                for (let i = 0; i < t.length; i++) {
                    console.log(t[i].latitude);
                    t_marker = new naver.maps.Marker({
                        // 생성될 마커의 위치

                        position: new naver.maps.LatLng(t[i].latitude, t[i].longitude),
                        // 마커를 표시할 Map 객체
                        icon: {

                            content: '<div style="width:50px;height:20px;background-color:#555555;color:#ffffff;border-radius:3px;font-size:11px;box-shadow:2px 2px 3px #afafaf;text-align:center;display:flex;justify-content:center;align-items:center">'+t[i].name+'</div>'
,
                            anchor: new naver.maps.Point(0, 50),  // 마커의 위치 설정
                        },
                        map: map,
                        idx:i,
                    });

                    markers.push(t_marker);
                }

                //return data;
                console.log('121',markers);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
        // console.log('64 data: ', v_data);



    }, []);


    function reset_markers(){
        console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>',markers.length);
        for(let i=0;i<markers.length;i++){
            console.log(markers[i]);
            markers[i].setMap(null);
        }
    }

    function show_marker(t_idx){
        for(let i=0;i<markers.length;i++){
             if( markers[i].idx == t_idx ){markers[i].setMap(map);}
        }
    }

    function show_all_markers(){
        for(let i=0;i<markers.length;i++){
            markers[i].setMap(map);
        }
    }

    function load_vehicle_data() {
        let ret = [];
        fetch(
            "https://app-dev.korea-turtle.co.kr/api/v2-iot/admin/map"
        ).then((res) => res.json());
        console.log('73 vehicel fetch data ', ret);
    }

/*
    const updateMarkerColor = (idx, newColor) => {
        setVehicles((prevVehicles) =>
          prevVehicles.map((vehicle) =>
            vehicle.idx === idx ? { ...vehicle, color: newColor } : vehicle
          )
        );
      };
*/


    let mapInstance = null;

    return (
        <div style={{ width: '100vw', height: '100vh', padding: '0px', margin: '0px' }}>
            <TurtleAppBar />

            {/*<div style={{position:'fixed',left:'0px',top:'0px',width:'200px',height:'100vh',zIndex:5,backgroundColor:'#ffffff',paddingTop:'80px',paddingLeft:'10px'}}>

           </div>*/}



        
            <div ref={mapRef} id="map" style={{ width: '100%', height: '100%', marginTop: '0px', backgroundColor: '#ffcc00', padding: '0px', margin: "'0px" }} />
           
            <div style={{position: 'absolute', width: '300px', top: '80px', right: '10px', backgroundColor: '#ffffff', boxShadow: '3px 3px 5px #555555', borderRadius: '5px', zIndex: 5, padding: '15px', fontSize:'11px' }}>

            <FormControl  key={vehicles}>
                        <InputLabel variant="standard" htmlFor="uncontrolled-native" >
                            Vehicle
                        </InputLabel>
                        <NativeSelect
                            key={vehicles}
                            onChange={(t) => { reset_markers();if( t.target.value == -1 ){show_all_markers();}else{show_marker(t.target.value);} setSelectedVehicle(t.target.value) }}
                            sx={{ width:'180px',fontSize: '13px' }}
                            defaultValue={-1}
                            inputProps={{
                                name: 'age',
                                id: 'uncontrolled-native',

                            }}
                        >
                            <option value={-1} >ALL</option>
                            {vehicles.map((item) => <option value={item.idx}>{item.name} ({item.qr_code})</option>)}

                        </NativeSelect>
               
                    </FormControl>


                { selectedVehicle == -1 ? <div style={{display:'none'}}></div> : <div style={{ padding: '0px', marginTop: '10px', lineHeight: '30px', fontFamily: 'Roboto, Helvetica, Arial, Sans-serif', fontSize:'11px' }}>
                    NAME: {vehicles[selectedVehicle].name}<br />
                    QR CODE: {vehicles[selectedVehicle].qr_code}<br />
                    IMEI: {vehicles[selectedVehicle].imei}<br />
                    LATITUDE: {vehicles[selectedVehicle].latitude}<br />
                    LONGITUDE: {vehicles[selectedVehicle].longitude}<br />
                    BATTERY: {vehicles[selectedVehicle].battery}%<br />
                    LOCKED: {vehicles[selectedVehicle].locked}<br /><br />
                    <div style={{width:'100%',display:'inline-block',textAlign:'right'}}><Button style={{marginLeft:'0px'}}  size="small" variant="contained">UNLOCK</Button>
                    <Button style={{marginLeft:'10px'}} size="small" variant="outlined">LOCK</Button></div>
                </div> }
            </div>

        </div>
    );

}
