import React from 'react';
// import Header from '../lib-ui/Header';
import { useRef, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import TurtleAppBar from '../lib/AppBar';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import axios from 'axios';
import Snackbar from '@mui/material/Snackbar';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
















let map = null;
var markers = [];

export default () => {

    const [searchParams, setSearchParams] = useSearchParams();

    const [vehicles, setVehicles] = useState([]);
    const [apiKey, setApiKey] = useState('');

    const [selectedVehicle, setSelectedVehicle] = useState(-1);
    const [markerColor, setrMarkerColor] = useState([]);
    const [controlMsg, setControlMsg] = useState('');
    const [flagControlMsg, setFlagControlMsg] = useState(false);
    const [controlResult, setControlResult] = useState('');



    const mapRef = useRef(null);
    const lat = 36.347939;//37.508225;
    const lng = 127.369223;//127.060826;
    const { naver } = window;

    //const mapRef = useRef(null);

    useEffect(() => {

        const key = searchParams.get('key');

        if (key == undefined || key == null) {
            alert("INVALID ACCESS");
            return;
        }
        setApiKey(key);

        // Check if naver is defined (API script loaded)
        if (!window.naver) return;

        const mapOptions = {
            center: new window.naver.maps.LatLng(lat, lng), //(37.5665, 126.9780), // Seoul coordinates
            zoom: 18,
            padding: 0,
        };

        // Initialize the map
        map = new window.naver.maps.Map(mapRef.current, mapOptions);
        const markerContent = `
  <div style="width:50px;height:20px;background-color:#555555;color:#ffffff;border-radius:3px;font-size:11px;box-shadow:2px 2px 3px #afafaf;text-align:center;display:flex;justify-content:center;align-items:center">YS10091</div>
`;


        /*
                new naver.maps.Marker({
                    // 생성될 마커의 위치
                    position: new naver.maps.LatLng(37.508622, 127.062154),
                    // 마커를 표시할 Map 객체
                    icon: {
                        content: markerContent,
                        anchor: new naver.maps.Point(0, 50),  // 마커의 위치 설정
                    },
                    map: map
                });
        */

        /*
        let v = [];
        for(let i=0;i<500;i++){
            v.push({
                id:i,
                name:'Turtle_'+i,
                imei:'IMEI-'+i,
                qr_code: 'YS_'+i
            });
        }

        setVehicles(v);
        */
        //  
        // console.log('64 data: ', v_data);

        let tc = [];

        const fetchData = async () => {
            try {
                const response = await fetch('https://app-dev.korea-turtle.co.kr/api/v2/admin/map?key=' + apiKey);
                const data = await response.json();
                //  console.log('72', data.data);
                let t = [];
                for (let i = 0; i < data.data.vehicle_list.length; i++) {
                    t.push({
                        id: data.data.vehicle_list[i].id,
                        idx: i,
                        name: data.data.vehicle_list[i].name,
                        qr_code: data.data.vehicle_list[i].qr_code,
                        imei: data.data.vehicle_list[i].imei,
                        battery: data.data.vehicle_list[i].battery,
                        latitude: parseFloat(data.data.vehicle_list[i].latitude),
                        longitude: parseFloat(data.data.vehicle_list[i].longitude),
                        locked: data.data.vehicle_list[i].is_locked == 1 ? 'YES' : 'NO'
                    });




                }

                let t_marker;
                setVehicles(t);
                console.log(t.length);
                for (let i = 0; i < t.length; i++) {
                    console.log(t[i].latitude);
                    t_marker = new naver.maps.Marker({
                        // 생성될 마커의 위치

                        position: new naver.maps.LatLng(t[i].latitude, t[i].longitude),
                        // 마커를 표시할 Map 객체
                        icon: {

                            content: '<div style="width:50px;height:20px;background-color:#555555;color:#ffffff;border-radius:3px;font-size:11px;box-shadow:2px 2px 3px #afafaf;text-align:center;display:flex;justify-content:center;align-items:center">' + t[i].name + '</div>'
                            ,
                            anchor: new naver.maps.Point(0, 50),  // 마커의 위치 설정
                        },
                        map: map,
                        idx: i,
                    });

                    markers.push(t_marker);
                }

                //return data;
                console.log('121', markers);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
        // console.log('64 data: ', v_data);



    }, []);


    function closeControlMsg() {
        setFlagControlMsg(false);
        setControlMsg('');
    }

    function reset_markers() {
        console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>', markers.length);
        for (let i = 0; i < markers.length; i++) {
            console.log(markers[i]);
            markers[i].setMap(null);
        }
    }

    function show_marker(t_idx) {
        for (let i = 0; i < markers.length; i++) {
            if (markers[i].idx == t_idx) { markers[i].setMap(map); }
        }
    }

    function show_all_markers() {
        for (let i = 0; i < markers.length; i++) {
            markers[i].setMap(map);
        }
    }

    function load_vehicle_data() {
        let ret = [];
        fetch(
            "https://app-dev.korea-turtle.co.kr/api/v2/admin/map"
        ).then((res) => res.json());
        console.log('73 vehicel fetch data ', ret);
    }

    function lock_vehicle(imei) {

        axios.post('https://app-dev.korea-turtle.co.kr/api/v2/admin/vehicle/lock?key='+apiKey, {
            imei: imei,
        }, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
            .then(response => {
                console.log('Success:', response.data);
                if (response.data.result) {
                    setControlMsg('YES');
                    setControlMsg('Vehicle locked.');
                    setFlagControlMsg(true);
                    setControlResult(false);
                } else {
                    setControlMsg('');

                    setControlMsg('Vehicle lock failed.');
                    setFlagControlMsg(true);
                    setControlResult(false);
                }

            })
            .catch(error => {
                setControlMsg('');

                setControlMsg('Vehicle lock failed.');
                setFlagControlMsg(true);
                setControlResult(false);

                console.error('Error:', error);
            });
    }

    function unlock_vehicle(imei) {

        axios.post('https://app-dev.korea-turtle.co.kr/api/v2/admin/vehicle/unlock?key='+apiKey, {
            imei: imei,
        }, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
            .then(response => {
                console.log('Success:', response.data);
                if (response.data.result) {
                    setControlMsg('NO');
                    setControlMsg('Vehicle unlocked.');
                    setFlagControlMsg(true);
                    setControlResult(false);
                } else {
                    setControlMsg('');

                    setControlMsg('Vehicle unlock failed.');
                    setFlagControlMsg(true);
                    setControlResult(false);
                }

            })
            .catch(error => {
                console.error('Error:', error);
                setControlMsg('');

                setControlMsg('Vehicle unlock failed.');
                setFlagControlMsg(true);
                setControlResult(false);

                console.error('Error:', error);
            });
    }

    /*
        const updateMarkerColor = (idx, newColor) => {
            setVehicles((prevVehicles) =>
              prevVehicles.map((vehicle) =>
                vehicle.idx === idx ? { ...vehicle, color: newColor } : vehicle
              )
            );
          };
    */





    function vehicle_command(imei, cmd) {

        axios.post('https://app-dev.korea-turtle.co.kr/api/v2/admin/vehicle/command?key='+apiKey, {
            imei: imei,
            command: cmd
        }, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
            .then(response => {
                console.log('Success:', response.data);
                if (response.data.result) {
                    setControlMsg('NO');
                    setControlMsg('Vehicle commend sent successfully.');
                    setFlagControlMsg(true);
                    setControlResult(false);
                } else {
                    setControlMsg('');

                    setControlMsg('Vehicle command failed.');
                    setFlagControlMsg(true);
                    setControlResult(false);
                }

            })
            .catch(error => {
                console.error('Error:', error);
                setControlMsg('');

                setControlMsg('Vehicle command failed.');
                setFlagControlMsg(true);
                setControlResult(false);

                console.error('Error:', error);
            });
    }








async function load_map(){
    try {
        const response = await fetch('https://app-dev.korea-turtle.co.kr/api/v2/admin/map?key=' + apiKey);
        const data = await response.json();
        //  console.log('72', data.data);
        let t = [];
        for (let i = 0; i < data.data.vehicle_list.length; i++) {
            t.push({
                id: data.data.vehicle_list[i].id,
                idx: i,
                name: data.data.vehicle_list[i].speed_mode == null || data.data.vehicle_list[i].is_locked == null ?'(X)' + data.data.vehicle_list[i].name  : data.data.vehicle_list[i].name,
                qr_code: data.data.vehicle_list[i].qr_code,
                imei: data.data.vehicle_list[i].imei,
                battery: data.data.vehicle_list[i].battery,
                latitude: parseFloat(data.data.vehicle_list[i].latitude),
                longitude: parseFloat(data.data.vehicle_list[i].longitude),
                locked: data.data.vehicle_list[i].is_locked == 1 ? 'YES' : 'NO',
                status:data.data.vehicle_list[i].status,
                color: data.data.vehicle_list[i].speed_mode == null || data.data.vehicle_list[i].is_locked == null ? '#555555' : '#1111ff',
                data: data.data.vehicle_list[i].speed_mode == null || data.data.vehicle_list[i].is_Locked == null ? 'FAILED' : 'PASSED',
            });




        }

        let t_marker;
        setVehicles(t);
        console.log(t.length);
        for (let i = 0; i < t.length; i++) {
            console.log(t[i].latitude);
            t_marker = new naver.maps.Marker({
                // 생성될 마커의 위치

                position: new naver.maps.LatLng(t[i].latitude, t[i].longitude),
                // 마커를 표시할 Map 객체
                icon: {

                    content: '<div style="width:58px;height:35px;background-color:'+t[i].color+';color:#ffffff;border-radius:3px;font-size:11px;box-shadow:2px 2px 3px #afafaf;text-align:center;display:flex;justify-content:center;align-items:center">' + t[i].qr_code + '<br />'+t[i].battery+'%</div>'
                    ,
                    anchor: new naver.maps.Point(0, 50),  // 마커의 위치 설정
                },
                map: map,
                idx: i,
            });

            markers.push(t_marker);
        }

        //return data;
        console.log('121', markers);
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}





    let mapInstance = null;

    return (
        <><div style={{ width: '100vw', height: '100vh', padding: '0px', margin: '0px' }}>



            <Box sx={{ flexGrow: 1 }}>
                <AppBar position="fixed">
                    <Toolbar>
                        {/*<IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>*/}
                        <Typography variant="h6" component="div" sx={{ width: '200px', fontWeight: 'bold' }}>
                            Turtle
                        </Typography>





                        <Button size={"small"} style={{ color: '#ffffff' }}>Map</Button>
                        <Button size={"small"} style={{ color: '#a0a0a0', display:'none' }}>Ride</Button>


                        <Button size={"small"} style={{ color: '#ffffff', marginLeft: 'auto', }} onClick={()=>{load_map()}}>Load</Button>
                    </Toolbar>
                </AppBar>
            </Box>


            <div ref={mapRef} id="map" style={{ width: '100%', height: '100%', marginTop: '0px', backgroundColor: '#ffffff', padding: '0px', margin: "'0px" }} ></div>

            <div style={{ position: 'absolute', width: '300px', top: '80px', right: '10px', backgroundColor: '#ffffff', boxShadow: '3px 3px 5px #555555', borderRadius: '5px', zIndex: 5, padding: '15px', fontSize: '11px' }}>

                <FormControl key={vehicles}>
                    <InputLabel variant="standard" htmlFor="uncontrolled-native" >
                        Vehicle
                    </InputLabel>
                    <NativeSelect
                        key={vehicles}
                        onChange={(t) => { reset_markers(); if (t.target.value == -1) { show_all_markers(); } else { show_marker(t.target.value); } setControlMsg(''); setSelectedVehicle(t.target.value) }}
                        sx={{ width: '180px', fontSize: '13px' }}
                        defaultValue={-1}
                        inputProps={{
                            name: 'age',
                            id: 'uncontrolled-native',

                        }}
                    >
                        <option value={-1}>ALL</option>
                        {vehicles.map((item) => <option value={item.idx}>{item.name} ({item.qr_code})</option>)}

                    </NativeSelect>

                </FormControl>


                {selectedVehicle == -1 ? <div style={{ display: 'none' }}></div> : <div style={{ padding: '0px', marginTop: '10px', lineHeight: '30px', fontFamily: 'Roboto, Helvetica, Arial, Sans-serif', fontSize: '11px' }}>
                    NAME: {vehicles[selectedVehicle].name}<br />
                    QR CODE: {vehicles[selectedVehicle].qr_code}<br />
                    IMEI: {vehicles[selectedVehicle].imei}<br />
                    LATITUDE: {vehicles[selectedVehicle].latitude}<br />
                    LONGITUDE: {vehicles[selectedVehicle].longitude}<br />
                    BATTERY: {vehicles[selectedVehicle].battery}%<br />
                    LOCKED: {controlResult == '' ? vehicles[selectedVehicle].locked : controlResult}<br /><br />


                    <Button style={{ marginLeft: '10px' }} size="small" variant="outlined" onClick={() => { vehicle_command(vehicles[selectedVehicle].imei, 'C0') }}>C0 (Battery Cover)</Button><br />
                    <Button style={{ marginLeft: '10px' }} size="small" variant="outlined" onClick={() => { vehicle_command(vehicles[selectedVehicle].imei, 'C0') }}>D0 (Data #0)</Button><br />
                    <Button style={{ marginLeft: '10px' }} size="small" variant="outlined" onClick={() => { vehicle_command(vehicles[selectedVehicle].imei, 'C0') }}>S6 (Data #1)</Button><br />

                    <div style={{ width: '100%', display: 'inline-block', textAlign: 'right' }}>
                        <Button style={{ marginLeft: '0px' }} size="small" variant="contained" onClick={() => { unlock_vehicle(vehicles[selectedVehicle].imei) }}>UNLOCK</Button>
                        <Button style={{ marginLeft: '10px' }} size="small" variant="outlined" onClick={() => { lock_vehicle(vehicles[selectedVehicle].imei) }}>LOCK</Button></div>
                </div>}
            </div>

        </div>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={flagControlMsg}
                onClose={closeControlMsg}
                message={controlMsg}
                severity={"error"}
                key={'top' + 'center'}
            />
        </>
    );

}
