import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import Main from './pages/Main';
import MapControl from './page/MapControl';
import Main from './page/Main';

export default () => {
    return (
        <Router basename='/'>
            <Routes>
                
                <Route path="/admin/map" element={<MapControl />} />
            </Routes>
        </Router>
    );
}